import React, { useState, useRef, useEffect } from 'react';

const CrewAbilityCard = ({ item, onClick, previewLocked }) => {

    const [isUsed, setIsUsed] = useState(item.isUsed);
    useEffect(() => {
        setIsUsed(item.isUsed);
    }, [ item.isUsed ]);

    return (
        <div 
            className={`relative w-full font-shippori select-none ${isUsed && 'opacity-20'}`}
            style={{ pointerEvents: previewLocked ? 'none' : 'auto' }}
            onClick={() => {
                setIsUsed(!isUsed);
                onClick(item)
            }}
        >
            <div className={`bg-white w-28 rounded-lg overflow-hidden ${!item.done && 'border-8 ring-2 ring-black border-white'}`}>
                {item.image && <img src={item.image} className="w-full h-28 object-cover" alt={item.title} />}
                {(item.title || item.date) && (
                        <div className="bg-white pt-1.5 flex justify-between items-center">
                            {item.title && <h3 className="text-black font-bold text-2xs">{item.title}</h3>}
                        </div>
                    )}
            </div>
        </div>
    );
}

export default CrewAbilityCard;
